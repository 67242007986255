import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../components/logo"

function Header() {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="bg-primary">
      <div className="flex flex-wrap items-center justify-between container p-4 mx-auto md:py-6">
        <Link to="/">
          <div className="flex items-center text-white no-underline">
            <Logo />
          </div>
        </Link>

        <button
          className="flex items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `https://tin.re`,
              title: `Try Now`,
            },
            {
              route: `https://github.com/devpri/tinre`,
              title: `View on Github`,
            },
            {
              route: `https://www.devpri.com`,
              title: `Devpri.com`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
