import React from "react";

function Logo() {
  return (
    <svg version="1.1" height="28px" x="0px" y="0px" viewBox="0 0 99 28" overflow="visible" xmlSpace="preserve" className="inline-block"><g><path fill="#FFFFFF" d="M24,0h-8h-4v12H4H0v16h4h8h4V16h8h4V0H24z M12,24H4v-8h8V24z M24,12h-8V4h8V12z"></path></g> <g><path fill="#FFFFFF" d="M42.09,22h-3.39V8.82h-4.34V6h12.08v2.82h-4.34V22z"></path> <path fill="#FFFFFF" d="M48.89,22V6h3.39v16H48.89z"></path> <path fill="#FFFFFF" d="M70.51,22H66.2L59.24,9.9h-0.1c0.14,2.14,0.21,3.66,0.21,4.57V22h-3.03V6h4.28l6.95,11.98h0.08
		c-0.11-2.08-0.16-3.55-0.16-4.41V6h3.05V22z"></path> <path fill="#FFFFFF" d="M77.93,15.86V22h-3.39V6h4.66c2.17,0,3.78,0.4,4.83,1.19c1.04,0.79,1.56,1.99,1.56,3.61
		c0,0.94-0.26,1.78-0.78,2.51s-1.25,1.31-2.2,1.72c2.41,3.6,3.98,5.92,4.71,6.97h-3.76l-3.82-6.14H77.93z M77.93,13.1h1.09
		c1.07,0,1.86-0.18,2.37-0.54c0.51-0.36,0.77-0.92,0.77-1.69c0-0.76-0.26-1.3-0.78-1.62c-0.52-0.32-1.33-0.48-2.42-0.48h-1.03V13.1z
		"></path> <path fill="#FFFFFF" d="M98.55,22h-9.21V6h9.21v2.78h-5.82v3.51h5.42v2.78h-5.42v4.13h5.82V22z"></path></g></svg>
  );
}

export default Logo;
