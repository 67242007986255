import React from "react"

function Logo() {
  return (
    <footer className="bg-primary">
      <nav className="flex justify-between p-4 mx-auto text-sm text-white md:py-6">
        <div className="w-full text-center">
          © 2020 - Devpri SRL | Images Source:
          <a href="https://www.freepik.com/vectors/design"> freepik.com</a>
        </div>
      </nav>
    </footer>
  )
}

export default Logo
